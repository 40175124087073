<template>
  <v-list dense>
    <v-list-item
      tile
      link
      :class="isRouteActive(toProfile)"
      @click="navigateTo(toProfile)"
      >{{ $t("page.profile.sidebar.myProfile") }}</v-list-item
    >
    <v-list-item
      tile
      link
      :class="isRouteActive(toInvitations)"
      @click="navigateTo(toInvitations)"
      >{{ $t("page.profile.sidebar.myInvites") }}</v-list-item
    >
    <v-list-item
      tile
      link
      :class="isRouteActive(toContributions)"
      @click="navigateTo(toContributions)"
      >{{ $t("page.profile.sidebar.myContributions") }}</v-list-item
    >
    <!-- <v-list-item>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
  </v-list>
</template>
  
<script>
import * as Routes from "../../router/route-names";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    toProfile() {
      return Routes.PROFILE;
    },
    toInvitations() {
      return Routes.PROFILE_INVITATIONS;
    },
    toContributions() {
      return Routes.PROFILE_CONTRIBUTIONS;
    },
  },
  methods: {
    navigateTo(routeName) {
      this.$store.dispatch("navigateTo", { routeName });
    },
    isRouteActive(routeName) {
      if (this.$route.name == routeName) return "v-list-item--active";
      return "";
    },
  },
};
</script>